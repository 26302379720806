import Card from "@material-ui/core/Card";
import React from "react";
import "./MiniCardPlain.scss";

const MinicardPlain = (props) => {
  return (
    <Card
      style={{ borderRadius: "0px" }}
      className={`mini-plain-card-area ${props.MiniCardPlainCustomClass}`}
    >
      <div>
        <div className="mini-plain-mini-title-and-content-area">
          <div className="mini-plain-card-title">{props.data.title}</div>
          <div className="mini-plain-card-content">{props.data.subTitle}</div>
        </div>
      </div>
    </Card>
  );
};

export default MinicardPlain;
