import { Grid } from "@material-ui/core";
import { Link } from "gatsby";
import React, { useState } from "react";
import { animated } from "react-spring";
import styled from "styled-components";
import HoverCardWithButton from "../../components/HoverCardWithButton";
import MinicardPlain from "../../components/MiniCardPlain";
import "./SixHoverCards.scss";

const SixHoverCards = (props) => {
  const [hoverState1, sethoverState1] = useState(false);
  const [hoverState2, sethoverState2] = useState(false);
  const [hoverState3, sethoverState3] = useState(false);
  const [hoverState4, sethoverState4] = useState(false);
  const [hoverState5, sethoverState5] = useState(false);

  // const toggle = () => {
  //   set(!show);
  // };

  // const handleToggle = (event, value) => {
  //   // setState({ ...state, [event.target.name]: !(state[event.target.value]) });
  //   sethoverState1(!hoverState1);
  //   set(!show);
  // }

  return (
    <div className="body-container">
      <Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={4} className="card crd-spc">
            <Link
              target="_blank"
              className="services-link"
              to={`/client-story${props.SixHoverCardData.route1}`}
            >
              <div
                role="presentation"
                onMouseEnter={() => sethoverState1(true)}
                onMouseLeave={() => {
                  sethoverState1(false);
                  sethoverState2(false);
                  sethoverState3(false);
                  sethoverState4(false);
                  sethoverState5(false);
                }}
                // onMouseEnter={toggle}
                // onMouseLeave={toggle}
              >
                {/* {transitions1.map(({ item, key, props }) =>
        item ? (
          <FirstComponent style={props} name="hoverState1">
          <MinicardPlain MiniCardPlainCustomClass="bg-grey" data ={ prop.FiveCardObject.cardsData[0]} />
          </FirstComponent>
        ) : (
          <SecondComponent style={props} name="hoverState1">
           <HoverCardWithButton
                  HoverCardWithBtnCustomClass="hover-card-bg-odd"
                  HoverCardWithBtntitle={prop.SixHoverCardData.HoverCardWithBtntitle1}
                  HoverCardWithBtnIcon={prop.SixHoverCardData.HoverCardWithBtnIcon1}
                  HoverCardWithBtnIconAlt={prop.HoverCardWithBtnIconAlt1}
                  HoverCardIconClass={prop.HoverCardIconClass}
                />
          </SecondComponent>
        )
      )} */}
                {!hoverState1 ? (
                  <HoverCardWithButton
                    HoverCardWithBtnCustomClass="hover-card-bg-odd"
                    HoverCardWithBtntitle={
                      props.SixHoverCardData.HoverCardWithBtntitle1
                    }
                    HoverCardWithBtnIcon={
                      props.SixHoverCardData.HoverCardWithBtnIcon1
                    }
                    HoverCardWithBtnIconAlt={props.HoverCardWithBtnIconAlt1}
                    HoverCardIconClass={props.HoverCardIconClass}
                    route={props.SixHoverCardData.route1}
                  />
                ) : (
                  hoverState1 && (
                    <MinicardPlain
                      data={props.FiveCardObject.cardsData[0]}
                      MiniCardPlainCustomClass="bg-grey"
                    />
                  )
                )}
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className="card crd-spc">
            <Link
              target="_blank"
              className="services-link"
              to={`/client-story${props.SixHoverCardData.route2}`}
            >
              <div
                role="presentation"
                onMouseEnter={() => sethoverState2(true)}
                onMouseLeave={() => {
                  sethoverState1(false);
                  sethoverState2(false);
                  sethoverState3(false);
                  sethoverState4(false);
                  sethoverState5(false);
                }}
              >
                {/* {transitions2.map(({ item, key, props }) =>
        item ? (
          <FirstComponent style={props} name="hoverState1">
           <MinicardPlain  data ={ prop.FiveCardObject.cardsData[1]}  MiniCardPlainCustomClass="bg-primary"/>
          </FirstComponent>
        ) : (
          <SecondComponent style={props} name="hoverState1">
           <HoverCardWithButton
                  HoverCardWithBtnCustomClass="hover-card-bg-even"
                  HoverCardWithBtntitle={prop.SixHoverCardData.HoverCardWithBtntitle2}
                  HoverCardWithBtnIcon={prop.SixHoverCardData.HoverCardWithBtnIcon2}
                  HoverCardWithBtnIconAlt={prop.SixHoverCardData.HoverCardWithBtnIconAlt2}
                  HoverCardIconClass={prop.HoverCardIconClass}
                />
          </SecondComponent>
        )
      )} */}
                {!hoverState2 && (
                  <HoverCardWithButton
                    HoverCardWithBtnCustomClass="hover-card-bg-even"
                    HoverCardWithBtntitle={
                      props.SixHoverCardData.HoverCardWithBtntitle2
                    }
                    HoverCardWithBtnIcon={
                      props.SixHoverCardData.HoverCardWithBtnIcon2
                    }
                    HoverCardWithBtnIconAlt={
                      props.SixHoverCardData.HoverCardWithBtnIconAlt2
                    }
                    HoverCardIconClass={props.HoverCardIconClass}
                    route={props.SixHoverCardData.route2}
                  />
                )}
                {hoverState2 && (
                  <MinicardPlain
                    data={props.FiveCardObject.cardsData[1]}
                    MiniCardPlainCustomClass="bg-primary"
                  />
                )}
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className="card crd-spc">
            <Link
              target="_blank"
              className="services-link"
              to={`/client-story${props.SixHoverCardData.route3}`}
            >
              <div
                role="presentation"
                onMouseEnter={() => sethoverState3(true)}
                onMouseLeave={() => {
                  sethoverState1(false);
                  sethoverState2(false);
                  sethoverState3(false);
                  sethoverState4(false);
                  sethoverState5(false);
                }}
              >
                {/* {transitions3.map(({ item, key, props }) =>
        item ? (
          <FirstComponent style={props} name="hoverState1">
           <MinicardPlain  data ={ prop.FiveCardObject.cardsData[2]}  MiniCardPlainCustomClass="bg-grey"/>
          </FirstComponent>
        ) : (
          <SecondComponent style={props} name="hoverState1">
           <HoverCardWithButton
                  HoverCardWithBtnCustomClass="hover-card-bg-odd"
                  HoverCardWithBtntitle={prop.SixHoverCardData.HoverCardWithBtntitle3}
                  HoverCardWithBtnIcon={prop.SixHoverCardData.HoverCardWithBtnIcon3}
                  HoverCardWithBtnIconAlt={prop.SixHoverCardData.HoverCardWithBtnIconAlt3}
                  HoverCardIconClass={prop.HoverCardIconClass}
                />
          </SecondComponent>
        )
      )} */}
                {!hoverState3 && (
                  <HoverCardWithButton
                    HoverCardWithBtnCustomClass="hover-card-bg-odd"
                    HoverCardWithBtntitle={
                      props.SixHoverCardData.HoverCardWithBtntitle3
                    }
                    HoverCardWithBtnIcon={
                      props.SixHoverCardData.HoverCardWithBtnIcon3
                    }
                    HoverCardWithBtnIconAlt={
                      props.SixHoverCardData.HoverCardWithBtnIconAlt3
                    }
                    HoverCardIconClass={props.HoverCardIconClass}
                    route={props.SixHoverCardData.route3}
                  />
                )}
                {hoverState3 && (
                  <MinicardPlain
                    data={props.FiveCardObject.cardsData[2]}
                    MiniCardPlainCustomClass="bg-grey"
                  />
                )}
              </div>
            </Link>
          </Grid>
        </Grid>
        <Grid className="mt-15" container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={4} className="card crd-spc">
            <Link
              target="_blank"
              className="services-link"
              to={`/client-story${props.SixHoverCardData.route4}`}
            >
              <div
                role="presentation"
                onMouseEnter={() => sethoverState4(true)}
                onMouseLeave={() => {
                  sethoverState1(false);
                  sethoverState2(false);
                  sethoverState3(false);
                  sethoverState4(false);
                  sethoverState5(false);
                }}
              >
                {/* {transitions4.map(({ item, key, props }) =>
        item ? (
          <FirstComponent style={props} name="hoverState1">
           <MinicardPlain  data ={ prop.FiveCardObject.cardsData[3]}  MiniCardPlainCustomClass="bg-primary"/>
          </FirstComponent>
        ) : (
          <SecondComponent style={props} name="hoverState1">
           <HoverCardWithButton
                  HoverCardWithBtnCustomClass="hover-card-bg-even"
                  HoverCardWithBtntitle={prop.SixHoverCardData.HoverCardWithBtntitle4}
                  HoverCardWithBtnIcon={prop.SixHoverCardData.HoverCardWithBtnIcon4}
                  HoverCardWithBtnIconAlt={prop.SixHoverCardData.HoverCardWithBtnIconAlt4}
                  HoverCardIconClass={prop.HoverCardIconClass}
                />
          </SecondComponent>
        )
      )} */}
                {!hoverState4 && (
                  <HoverCardWithButton
                    HoverCardWithBtnCustomClass="hover-card-bg-even"
                    HoverCardWithBtntitle={
                      props.SixHoverCardData.HoverCardWithBtntitle4
                    }
                    HoverCardWithBtnIcon={
                      props.SixHoverCardData.HoverCardWithBtnIcon4
                    }
                    HoverCardWithBtnIconAlt={
                      props.SixHoverCardData.HoverCardWithBtnIconAlt4
                    }
                    HoverCardIconClass={props.HoverCardIconClass}
                    route={props.SixHoverCardData.route4}
                  />
                )}
                {hoverState4 && (
                  <MinicardPlain
                    data={props.FiveCardObject.cardsData[3]}
                    MiniCardPlainCustomClass="bg-primary"
                  />
                )}
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className="card crd-spc">
            <Link
              target="_blank"
              className="services-link"
              to={`/client-story${props.SixHoverCardData.route5}`}
            >
              <div
                role="presentation"
                onMouseEnter={() => sethoverState5(true)}
                onMouseLeave={() => {
                  sethoverState1(false);
                  sethoverState2(false);
                  sethoverState3(false);
                  sethoverState4(false);
                  sethoverState5(false);
                }}
              >
                {/* {transitions5.map(({ item, key, props }) =>
        item ? (
          <FirstComponent style={props} name="hoverState1">
           <MinicardPlain  data ={ prop.FiveCardObject.cardsData[4]}  MiniCardPlainCustomClass="bg-grey"/>
          </FirstComponent>
        ) : (
          <SecondComponent style={props} name="hoverState1">
           <HoverCardWithButton
                  HoverCardWithBtnCustomClass="hover-card-bg-odd"
                  HoverCardWithBtntitle={prop.SixHoverCardData.HoverCardWithBtntitle5}
                  HoverCardWithBtnIcon={prop.SixHoverCardData.HoverCardWithBtnIcon5}
                  HoverCardWithBtnIconAlt={prop.SixHoverCardData.HoverCardWithBtnIconAlt5}
                  HoverCardIconClass={prop.HoverCardIconClass}
                />
          </SecondComponent>
        )
      )} */}
                {!hoverState5 && (
                  <HoverCardWithButton
                    HoverCardWithBtnCustomClass="hover-card-bg-odd"
                    HoverCardWithBtntitle={
                      props.SixHoverCardData.HoverCardWithBtntitle5
                    }
                    HoverCardWithBtnIcon={
                      props.SixHoverCardData.HoverCardWithBtnIcon5
                    }
                    HoverCardWithBtnIconAlt={
                      props.SixHoverCardData.HoverCardWithBtnIconAlt5
                    }
                    HoverCardIconClass={props.HoverCardIconClass}
                    route={props.SixHoverCardData.route5}
                  />
                )}
                {hoverState5 && (
                  <MinicardPlain
                    data={props.FiveCardObject.cardsData[4]}
                    MiniCardPlainCustomClass="bg-grey"
                  />
                )}
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className="card crd-spc">
            <HoverCardWithButton
              HoverCardWithBtnCustomClass="hover-card-bg-even"
              HoverCardWithBtnvariant={
                props.SixHoverCardData.HoverCardWithBtnvariant
              }
              HoverCardWithBtncolor={
                props.SixHoverCardData.HoverCardWithBtncolor
              }
              HoverCardWithBtnlabel={
                props.SixHoverCardData.HoverCardWithBtnlabel
              }
              HoverCardWithBtntitle={
                props.SixHoverCardData.HoverCardWithBtntitle
              }
              route={props.SixHoverCardData.route}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const FirstComponent = styled(animated.div)`
  // background-color: crimson;
  // color: white;
  // padding: 2%;
`;
const SecondComponent = styled(animated.div)`
  // background-color: teal;
  // color: white;
  // padding: 2%;
`;

const Btn = styled.button`
  padding: 1%;
  font-size: 1.2rem;
  text-transform: capitalize;
`;

export default SixHoverCards;
