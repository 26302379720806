import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import HoverCardWithBtnIcon4 from "../assets/Icons/CRM.svg";
import HoverCardWithBtnIcon1 from "../assets/Icons/e_commerce.svg";
import RightArrow from "../assets/Icons/go.svg";
import HoverCardWithBtnIcon5 from "../assets/Icons/LMS.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import HoverCardWithBtnIcon3 from "../assets/Icons/Platformn_Based_Product_Development.svg";
import HoverCardWithBtnIcon2 from "../assets/Icons/saas.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import ClickToViewCardImg1 from "../assets/Images/ClickToViewCard/Business-Model-Valuation.png";
import ClickToViewCardImg5 from "../assets/Images/ClickToViewCard/Launch-and-continuous-upgrade.png";
import ClickToViewCardImg4 from "../assets/Images/ClickToViewCard/Minimum_Viable_Product.png";
import ClickToViewCardImg2 from "../assets/Images/ClickToViewCard/Proof-of-Concept.png";
import ClickToViewCardImg3 from "../assets/Images/ClickToViewCard/Prototype.png";
import ClickToViewMegaViewCarousalImg2 from "../assets/Images/ClickToViewMegaViewCarousal/Contact_and_first_proposal.png";
import ClickToViewMegaViewCarousalImg4 from "../assets/Images/ClickToViewMegaViewCarousal/Final_proposal_and_release_plan.png";
import ClickToViewMegaViewCarousalImg1 from "../assets/Images/ClickToViewMegaViewCarousal/Lean_startup_mindset.png";
import ClickToViewMegaViewCarousalImg3 from "../assets/Images/ClickToViewMegaViewCarousal/Product_Workshops_with_the_team.png";
import MiniImgBnrBgImg from "../assets/Images/contact_us_bg.png";
import ChartImage from "../assets/Images/FPD/Full Product Development Cycle Graph.png";
import BannerBgImage from "../assets/Images/full-product-dev-banner.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import IconCardListIcon1 from "../assets/Images/IconCardList/Creative_Idea.svg";
import IconCardListIcon3 from "../assets/Images/IconCardList/Feedback_Audience.svg";
import IconCardListIcon6 from "../assets/Images/IconCardList/MRR.svg";
import IconCardListIcon4 from "../assets/Images/IconCardList/Presentation.svg";
import IconCardListIcon2 from "../assets/Images/IconCardList/Software_Companies.svg";
import IconCardListIcon5 from "../assets/Images/IconCardList/Work_Flow.svg";
import ogimage from "../assets/Images/ogImages/OG_Full-Prod-Dev.png";
import awardsForMobile from "../assets/Images/scrollImages/Group_6110_2x.png";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
import MobileMovieSlider from "../components/MobileMovieSlider";
import DataService from "../services/api/data_services";
import { BASE_SITE_URL } from "../shared/constants";
import BlurCardList from "../templates/BlurCardList";
import ClickToViewCard from "../templates/ClickToViewCard";
import ClickToViewMegaViewCarousal from "../templates/ClickToViewMegaViewCarousal";
import IconCardList from "../templates/IconCardList";
import ImageBanner from "../templates/ImageBanner";
import Infoview from "../templates/InfoView";
import Megaview from "../templates/MegaView";
import MiniImageBanner from "../templates/MiniImageBanner";
import Miniview from "../templates/MiniView";
import SixHoverCards from "../templates/SixHoverCards";
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

const FullProductDevelopment = () => {
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=PD - Custom Software Development"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=PD - Custom Software Development&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);

  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: (
      <>
        Full Product <br /> Development
      </>
    ),
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <>
        Design, Develop & Deliver
        <br /> <span className="red-line3letter">Gam</span>e-Changing Digital
        Products
      </>
    ),
    tag: "h2",
    InfoviewContent: (
      <>
        We provide quick ideation, prototyping, MVP development to ensure that
        your product meets the needs of the market. At every stage of your
        product development cycle, we can help you scale and expand your
        business to a new level. Our product design and app development team
        will recommend the best strategy to build, test, and improve your
        product before it releases to the market.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Mini Image Banner
   */

  const miniImageBannerData = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to estimate your product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " to ",
    PlainContent: "estimate your product",
    route: "/contact-us",
  };

  /**
   * Click To View Card
   */

  const ClickToViewCardData = [
    {
      title: "Business Model Valuation (BMV)",
      heading: (
        <>
          <span className="black-line3letter">Bus</span>iness Model Valuation
        </>
      ),
      content:
        "The accurate valuation of a close-knit business is a key tool for the business owner to assess both opportunities and opportunity costs as they plan for future growth and eventual transition. We work closely with you to analyse your company's financial statements first and consider comparable transactions, industry ratios and other quantitative and qualitative information. We will then advise you on your product development journey and help you with the product roadmap ahead of time.",
      image: ClickToViewCardImg1,
    },
    {
      title: "Proof of Concept (PoC)",
      heading: (
        <>
          <span className="black-line3letter">Pro</span>of of Concept
        </>
      ),
      content:
        "When you present a Proof of Concept to a potential investor along with your business plan and budget, it can be a turning point for your business. At Digiryte, we will help develop a Proof of Concept physical product quickly and cost-effectively. With a tangible product that stakeholders can see and touch, this can increase their trust in your business.",
      image: ClickToViewCardImg2,
    },
    {
      title: "Prototype",
      heading: (
        <>
          <span className="black-line3letter">Pro</span>totype
        </>
      ),
      content:
        "In order to remain competitive, companies want to increase the degree of innovation and shorten product development times. Prototyping has become a decisive tool to achieve this objective. The Digiryte team will help you to create a responsible, technically feasible, documented prototype.",
      image: ClickToViewCardImg3,
    },
    {
      title: "Minimum Viable Product (MVP)",
      heading: (
        <>
          <span className="black-line3letter">Min</span>imum Viable Product
        </>
      ),
      content:
        "The MVP development provides various advantages for start-ups, including market validation, optimisation of resources and early acquisition of users. The development of an MVP is the best way of early retrieval of feedback from real users without risking releasing a product that will not work on the market.",
      image: ClickToViewCardImg4,
    },
    {
      title: "Launch & Continuous Upgrade",
      heading: (
        <>
          <span className="black-line3letter">Lau</span>nch & Continuous Upgrade
        </>
      ),
      content:
        "Once your product has been launched in the market, it’s easily assumed that your product development process has been completed. However, it is important for your users to feel that the product is constantly being updated. The Digiryte team will guide you in fixing minor or major bugs and promoting the continued use of the product.",
      image: ClickToViewCardImg5,
    },
  ];

  /**
   * Click To View Mega View Carousal
   */

  const ClickToViewMegaViewCarousalData = {
    data: [
      {
        title: "STEP 1: Lean startup approach",
        download: "no",
        bottom: "Collaboration Process",
        displayFIndoutMore: "no",
        card1data: {
          meta_title: "Lean startup approach",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "Having a business mind is a skill. No skill is developed by tactics alone. Developing a skill at a very elite level requires behavioural changes, mindset shifts and expanding your personality. And it can take years to develop it. If you blindly follow tactics without the proper mindset, you won’t be executing those tactics the way they ought to be executed.  Let's take an example: Build, Iterate and Measure are the key concepts of Lean startup. There is nothing wrong with this concept. But let’s dig a few layers deeper to really understand what it means. If you directly apply this concept it will not work.",
          mega_view_image: ClickToViewMegaViewCarousalImg1,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "lean-mindset-to-launch-a-successful-software-product",
          slug_type: "article",
        },
      },
      {
        title: "STEP 2: Contact and first proposal",
        card1data: {
          meta_title: "Contact and first proposal",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "After you contact Digiryte, we gather as much information possible about the product idea and spend our time understanding your business needs and identifying your potential customers to ensure we build a platform which will be familiar to your potential users and stands ahead of your competitors. After considering all the factors, we aim to respond in the next 2-3 business days with a pre-proposal which usually consists of a median SDLC ballpark estimate, the bespoke development approach we created based on your requirements and the various steps explaining our working methodology.",
          mega_view_image: ClickToViewMegaViewCarousalImg2,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "digiryte-s-proposal-and-working-methodology",
          slug_type: "article",
        },
      },
      {
        title: "STEP 3: Product workshop and consulting",
        card1data: {
          meta_title: "Product workshop and consulting",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "Whether you have an idea for building a mobile app, or you need to build complex internal software in a large corporation, all great digital products should be built from a solid foundation. This means gaining a deep understanding of your business needs, what problem or opportunity you’re trying to solve, an idea of your users behaviours and preferences and the value that the product will add.",
          mega_view_image: ClickToViewMegaViewCarousalImg3,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "4-steps-of-digiryte-s-successful-product-workshop-methodology",
          slug_type: "article",
        },
      },
      {
        title: "STEP 4: Final proposal and release plan",
        card1data: {
          meta_title: "Final proposal and release plan",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "We position ourselves uniquely in the market by taking a higher approach to creating a win-win situation for us and the client to avoid major pitfalls during the development. Having worked closely with the University of Manchester, over time, we have researched and created various methodologies which help our clients and us understand the project workflow better and help us meet expectations, costs and timelines more accurately. Based on the medium (mobile, web or both) and scope of the project, we usually narrow down at least two to three possible methodologies which best suit the flow of the project.",
          mega_view_image: ClickToViewMegaViewCarousalImg4,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "final-proposal-and-release-plan-methodology-of-digiryte",
          slug_type: "article",
        },
      },
    ],
  };

  /**
   * Six Hover Card
   */

  const SixHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle:
      "We'd love to help with your full product development",
    HoverCardWithBtntitle1: "E-Commerce Website Development",
    HoverCardWithBtntitle2: "SAAS Product Development",
    HoverCardWithBtntitle3: "Platform-Based Product Development",
    HoverCardWithBtntitle4:
      "Customer Relationship Management (CRM) development",
    HoverCardWithBtntitle5: "Learning Management System (LMS) development",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardIconClass: "mb-10",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    route1: "/machine-learning-solution-for-a-german-e-commerce-startup",
    route2:
      "/process-automation-for-prop-tech-leader-for-their-aggressive-expansion",
    route3:
      "/building-the-world-s-first-blockchain-platform-to-reduce-bike-theft",
    route4: "/we-built-an-ai-system-to-manage-salon-chain-s-business-operation",
    route5: "/acuity-coaching-chose-digiryte-to-digitise-their-operations",
    route: "/contact-us",
  };
  //  5 array of objects-> title,content sakthi added
  const FiveCardObject = {
    cardsData: [
      {
        title: "Machine Learning Solution for a German e-commerce startup",
        subTitle:
          "Check our experience developing a smart Order Management System, using AI and advanced machine learning techniques to manage online orders efficiently.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning-solution-for-a-german-e-commerce-startup",
      },
      {
        title:
          "Process automation for Prop-Tech leader for their aggressive expansion",
        subTitle:
          "Learn how we digitally transformed Hinch Property Management to build customised web and mobile applications to manage their inventory operations.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route:
          "/process-automation-for-prop-tech-leader-for-their-aggressive-expansion",
      },
      {
        title:
          "Building the world’s first Blockchain platform to reduce bike theft",
        subTitle:
          "Digryte built the world’s first Blockchain platform to reduce bike theft. The system will automatically track the journey and current status of the bike.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route:
          "/building-the-world-s-first-blockchain-platform-to-reduce-bike-theft",
      },
      {
        title:
          "We built an AI System to manage salon chain’s business operation",
        subTitle:
          "Digiryte helped the salon chain with an effective analytical system to manage inventories that helped them gain insights across their business operations.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route:
          "/we-built-an-ai-system-to-manage-salon-chain-s-business-operation",
      },
      {
        title: "Acuity Coaching chose Digiryte to digitise their operations",
        subTitle:
          "Digiryte helps in deploying and managing executive coaching globally. The application is designed to provide online access through the Acuity coaching portal.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/acuity-coaching-chose-digiryte-to-digitise-their-operations",
      },
    ],
  };
  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon1,
      altTxt: "Two different flexible proposals with solution and pricing",
      heading: "Two different flexible proposals with solution and pricing",
    },
    {
      icon: IconCardListIcon2,
      altTxt: "Working piece of code at end of each milestone",
      heading: "Working piece of code at end of each milestone",
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Accessible customer care by accountable team",
      heading: "Accessible customer care by accountable team",
    },
    {
      icon: IconCardListIcon4,
      altTxt: "Product workshops and consulting by experts",
      heading: "Product workshops and consulting by experts",
    },
    {
      icon: IconCardListIcon5,
      altTxt: "Transparent development process",
      heading: "Transparent development process",
    },
    {
      icon: IconCardListIcon6,
      altTxt: "Contineous knowledge transfer",
      heading: "Contineous knowledge transfer",
    },
  ];
  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: awardsForMobile,
  };
  return (
    <Layout
      seo={{
        title: "What we do: Full Product Development | Digiryte",
        ogtitle: "What we do: Full Product Development | Digiryte",
        description:
          "Digiryte provides quick ideation, prototyping, MVP development to ensure that your product meets the needs of the market.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/full-product-development`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30"></div>
      <Infoview InfoviewData={InfoviewData} />
      <div className="mt-50"></div>
      <div className="body-container ttc">
        <h2 className="title-header">Our Full Product</h2>
        <br />
        <h2 className="title-header">
          <span className="red-line3letter">Dev</span>elopment Process
        </h2>
      </div>
      <div className="mt-30 body-container">
        <ClickToViewCard data={ClickToViewCardData} />
      </div>
      <div className="body-container mt-50">
        <div className="full-product-graph-title">
          Full Product Development Cycle Graph
        </div>
        <img
          style={{ maxWidth: "100%" }}
          src={ChartImage}
          alt="Mobile/Web Application Development"
        />
        <div
          style={{ textAlign: "right", fontSize: "12px", fontStyle: "italic" }}
        >
          Created by Digiryte R&D Team
        </div>
      </div>
      <div className="mt-70"></div>
      <MiniImageBanner miniImageBannerData={miniImageBannerData} />
      <div className="mt-70 body-container">
        <h3 className="title-header">
          <span className="red-line3letter">Our</span> Collaboration Process
        </h3>
      </div>
      <div className="mt-70"></div>
      <ClickToViewMegaViewCarousal
        ClickToViewMegaViewCarousalData={ClickToViewMegaViewCarousalData}
      />
      <div className="fpd-custom-title-margin body-container ttc">
        <h2 className="title-header">
          Full Product Development <br />
          <span className="red-line3letter">Sol</span>utions Across Industries
        </h2>
        <br />
      </div>
      <div className="mt-70"></div>
      <SixHoverCards
        SixHoverCardData={SixHoverCardData}
        FiveCardObject={FiveCardObject}
      />
      <div className="body-container mt-70">
        <div className="title-header ttc">
          <span className="red-line3letter">Our</span> Offer
        </div>
        <div className="mt-50 mbl-hide">
          <IconCardList IconCardListData={IconCardListData} />
        </div>
        <div className="mt-50">
          <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
        </div>
      </div>
      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}

      <div className="body-container mt-70">
        <div className="title-header ttc">
          <span className="red-line3letter">Oth</span>er Services
        </div>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default FullProductDevelopment;
